 
  import axios from 'axios';
  import { jwtDecode } from 'jwt-decode';

  const isTokenExpired = exp => Date.now() >= exp * 1000
 
 const axeRequestInterceptor = async (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
  
    if (!config.headers.Accept) {
      config.headers.Accept = "application/json";
    }
  
    const jwtToken = localStorage.getItem('peritos_token');
  
    if (jwtToken != null) {
      try {
        const jwtTokenDecoded = jwtDecode(jwtToken);
        if (isTokenExpired(jwtTokenDecoded.exp)) {
            window.location.href =
                window.location.protocol + "//" + window.location.host + "/";
          return;
        }
      } catch (err) {
        // Do nothing
      }
  
      if (jwtToken !== null) {
        config.headers.Authorization = jwtToken;
      }
    }
  
    config.validateStatus = (status) => status !== 403 && status < 500;
  
    return config;
  };
  
  const axeResponseInterceptor = async (error) => {
    if (error.response?.status === 403) {
        window.location.href =
        window.location.protocol + "//" + window.location.host + "/";
      return Promise.reject(error);
    }
  
    return Promise.reject(error);
  };

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axe.interceptors.request.use(axeRequestInterceptor);

axe.interceptors.response.use((response) => {
  return response;
}, axeResponseInterceptor)

axe.defaults.baseURL = process.env.REACT_APP_API_URL;

export default axe;
