import { LoginBox, LoginContainer } from "./Login.styles"
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import axe from "../axios/axios";

const schema = yup.object({
    username: yup
        .string()
        .required("The field is required"),
    password: yup
        .string()
        .required("The field is required"),
});

export const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const submitData = async (data) => {
        setError(null);
        setLoading(true);
        const response = await axe.post('/login', data);

        setLoading(false);

        if (response.status === 401) {
            setError('Invalid credentials');
            return;
        }

        if (response.status !== 200) {
            setError('Server Error');
            return;
        }

        const { access_token } = response.data;

        localStorage.setItem('peritos_token', access_token);

        navigate('/app');
    }

    useEffect(() => {
        const token = localStorage.getItem('peritos_token');

        if (token !== null) {
            try {
                const decoded = jwtDecode(token);

                if (Date.now() >= decoded.exp * 1000) {
                    localStorage.removeItem('peritos_token');
                } else {
                    navigate('/app');
                }
            } catch (err) {

            }
        }
    }, []);
      
    return <LoginContainer>
        <LoginBox onSubmit={handleSubmit(submitData)}>
            {error !== null &&
                <div className="general-error">{error}</div>
            }
            <label>Username</label>
            <input type="text" {...register('username')} />
            <div className="error">{errors.username?.message}</div>

            <label>Password</label>
            <input type="password" {...register('password')} />
            <div className="error">{errors.password?.message}</div>

            <button type="submit" className={loading ? 'loading' : ''}>Log In</button>
        </LoginBox>
    </LoginContainer>
}