import { Chat } from './components/Chat';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Login } from './components/Login';
import { Document } from './components/Document';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/app',
    element: <Chat />
  },
  {
    path: '/documents/:documentId',
    element: <Document />
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
