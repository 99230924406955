import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(30,30,30);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const DocumentBox = styled.div`
    width: calc(100% - 62px);
    max-width: 700px;
    padding: 20px 30px;
    margin: 50px auto;
    border-left: 1px solid lightblue;
    border-right: 1px solid lightblue;

    .links {
        display: flex;
        justify-content: end;

        .outside-link {
            background-color: coral;
            text-decoration: none;
            color: black;
            font-weight: bold;
            padding: 5px 20px;
            cursor: pointer;
            border-radius: 6px;
        }
    }

    h1 {
        color: lightblue;
    }

    h2 {
        color: white;
    }

    .categories {
        color: white;
    }

    .summary {
        color: white;
        padding: 10px 0;
        border-bottom: 1px solid white;

        .title {
            padding: 10px 0;
            font-weight: bold;
            border-bottom: 1px dashed white;
        }

        .content {
            padding: 10px 0;
        }
    }
`;