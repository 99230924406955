import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, DocumentBox } from './Document.styles';
import axe from '../axios/axios';

export const Document = () => {
    const { documentId } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [documentInfo, setDocumentInfo] = useState(null);

    useEffect(() => {
        const run = async () => {
            const response = await axe.get(`/app/documents/${documentId}`);

            setLoading(false);

            if (response.status !== 200) {
                setError('Server Error');
                return;
            }

            setDocumentInfo(response.data.document);
        }

        run();
    }, [documentId]);

    if (loading) {
        return <Container />
    }
    
    return <Container>
        <DocumentBox>
            <div className="links">
                <a href="#" className="outside-link" target="_blank">Citavi</a>
            </div>
            <h1>{documentInfo.title}</h1>
            <h2>{documentInfo.authors}</h2>
            <div className="categories">{documentInfo.category}</div>
            {documentInfo.summaries.map((s, index) => 
                <div key={index} className="summary">
                    <div className="title">{s.title}</div>
                    <div className="content">{s.summary}</div>
                </div>
            )}
            
        </DocumentBox>
    </Container>
};