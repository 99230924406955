import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(30,30,30);
    display: flex;
    flex-direction: column;
`;

export const AnswerContainer = styled.div`
    position: relative;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    height: 80%;
    border-bottom: 2px solid rgb(50,50,50);
    overflow-y: auto;
    overflow-x: hidden;

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const QuestionAsked = styled.div`
    width: 80%;
    max-width: 800px;
    margin: 10px;
    color: white;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: rgb(50,50,50);
    margin-left: calc(20% - 30px);
`;

export const Answer = styled.div`
    width: 80%;
    max-width: 800px;
    margin: 10px;
    color: white;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: rgb(40,40,40);

    .sources-display-button {
        margin-top: 10px;
        padding: 5px 20px;
        background-color: transparent;
        border: 1px solid lightblue;
        color: lightblue;
        cursor: pointer;
        transition: .3s;

        &:hover {
            background-color: rgba(255,255,255,.1)
        }
    }

    .sources-list {
        display: flex;
        flex-direction: column;
        border: 1px solid lightblue;
        border-top: none;
        border-bottom: none;

        .source {
            padding: 8px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lightblue;

            a {
                text-decoration: none;
                color: white;
            }

            .in-app-link {
                background-color: lightblue;
                color: black;
                font-weight: bold;
                padding: 5px 20px;
                margin: 5px;
                cursor: pointer;
                border-radius: 6px;
            }

            .outside-link {
                background-color: coral;
                color: black;
                font-weight: bold;
                padding: 5px 20px;
                cursor: pointer;
                border-radius: 6px;
            }
        }
    }
`;

export const Question = styled.textarea`
    width: calc(100% - 20px);
    height: 20%;
    min-height: 100px;
    margin: 10px auto;
    max-width: 800px;
    background-color: rgb(60,60,60);
    padding: 10px;
    outline: none;
    color: white;
`;