import styled from 'styled-components';

export const LoginContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(40,40,40);
`;

export const LoginBox = styled.form`
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    margin: auto;
    margin-top: 50px;
    width: 100%;
    max-width: 500px;

    .general-error {
        background-color: rgb(200,100,100);
        margin: 10px;
        padding: 10px;
        text-align: center;
    }

    label {
        font-weight: bold;
        padding: 5px 10px;
        color: white;
    }

    input {
        outline: none;
        margin: 10px;
        padding: 8px 10px;
        border: 1px solid grey;
        background-color: transparent;
        color: white;
    }

    .error {
        padding: 2px 10px;
        color: red;
    }

    button {
        background-color: white;
        margin: 10px;
        padding: 10px;
        outline: none;
        border: none;
        cursor: pointer;

        &.loading {
            background: grey;
            color: white;
        }
    }
`;