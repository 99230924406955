import { useEffect, useState } from "react";
import { Answer, AnswerContainer, Container, Question, QuestionAsked } from "./Chat.styles";
import { v4 as uuidv4 } from 'uuid';
import { sendChatMessageToken } from "./Chat.events";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from 'react-router-dom';
import axe from "../axios/axios";

export const Chat = () => {
    const navigate = useNavigate();
    const [conversationId, setConversationId] = useState(null);
    const [socket, setSocket] = useState(null);
    const [conversation, setConversation] = useState([]);
    const [answering, setAnswering] = useState('');
    const [answerSourcesOpened, setAnswerSourcesOpened] = useState(null);

    const sendQuestion = async (message) => {
        const oldConversation = [...conversation.map(({
            user,
            text,
        }) => ({
            user, text
        }))];
        setConversation((prev) => [...prev, {
            user: 'user',
            text: message,
        }]);

        setAnswering('');
        const response = await axe.post('/app/respond', {
            conversation_id: conversationId,
            question: message,
            conversation: oldConversation,
        });

        setAnswering(null);

        if (response.status === 200) {
            const { answer, sources } = response.data;

            setConversation((prev) => [...prev, {
                user: 'system',
                text: answer,
                sources,
            }]);
        }
    }

    const onKeyUpHandler = (e) => {
        if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
            sendQuestion(e.target.value.trim());
            e.target.value = '';
        }
    }

    useEffect(() => {
        if (conversationId !== null) {
            let options = {
                forceNew: true,
                query: {
                    conversationId,
                    token: localStorage.getItem('peritos_token'),
                }
            };
    
            const s = io(process.env.REACT_APP_SOCKET_URL, options);
            setSocket(s);
        }
    }, [conversationId]);

    useEffect(() => {
        const token = localStorage.getItem('peritos_token');

        if (token !== null) {
            try {
                const decoded = jwtDecode(token);

                if (Date.now() >= decoded.exp * 1000) {
                    localStorage.removeItem('peritos_token');
                    navigate('/');
                } else {
                    setConversationId(uuidv4());
                }
            } catch (err) {

            }
        } else {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (socket !== null) {
            socket.off(sendChatMessageToken).on(sendChatMessageToken, ({token}) => {
                setAnswering(prev => prev !== null ? prev + token : null);
            });
        }
    }, [socket]);

    return <Container>
        <AnswerContainer>
            {conversation.map((v, index) => {
                if (v.user === 'user') {
                    return  <QuestionAsked>{v.text}</QuestionAsked>;
                }

                return <Answer key={index}>
                    <div className="text">{v.text}</div>
                    {v.sources.length !== 0 &&
                        <div className="sources-display-button" onClick={() => setAnswerSourcesOpened(answerSourcesOpened === index ? null : index)}>Show Sources</div>
                    }
                    {answerSourcesOpened === index &&
                        <div className="sources-list">
                            {v.sources.map((s) => (
                                <div className="source" key={s.documentId}>
                                    <div className="title">
                                        {s.title}
                                    </div>
                                    {s.documentId !== s.title &&
                                        <div className="links">
                                            <Link to={`/documents/${s.documentId}`} target="_blank" className="in-app-link">Document</Link>
                                            <a href="#" className="outside-link" target="_blank">Citavi</a>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </Answer>;
            })}
            
            {answering !== '' && answering !== null &&
                <Answer>{answering}</Answer>
            }
        </AnswerContainer>
        <Question onKeyUp={onKeyUpHandler} />
    </Container>
};